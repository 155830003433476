import './App.css';
import Desktop from './desktop';

function App() {
  return (
    <Desktop/>
  );
}

export default App;
